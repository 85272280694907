import React from 'react';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import { Grid } from 'src/components';

import content from 'src/content/aktuelles/newsroom';
import subpageNavigations from 'src/data/subpage-navigations';

const NewsroomPage = ({ data }) => {
  const blogPosts = data.blogPosts.edges;
  const { headvisual } = data;
  return (
    <MainLayout
      title={content.title}
      headvisual={{
        image: headvisual,
        headline: content.headvisualHeadline,
        position: '75'
      }}
      subpageNavItems={subpageNavigations.aktuelles}
      activeNavItem={1}
      useParentPathname
    >
      <section>
        <Grid columns={2}>
          {blogPosts.map((post, index) => {
            if (post.node.title === 'Nicht löschen') return '';
            return (
              <Link
                key={index}
                to={`/aktuelles/newsroom/${post.node.slug}`}
                className="block bg-white shadow-md"
              >
                {post.node.featured_media && (
                  <BackgroundImage
                    className="w-full h-300px"
                    fluid={
                      post.node.featured_media.localFile.childImageSharp.fluid
                    }
                  />
                )}
                <div className="p-10">
                  <time className="inline-block mb-5">{post.node.date}</time>
                  <h3 className="-mt-2 mb-5">{post.node.title}</h3>
                  <span className="button button--gray-blue">Mehr lesen</span>
                </div>
              </Link>
            );
          })}
        </Grid>
      </section>
    </MainLayout>
  );
};

export default NewsroomPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-and-woman-holding-tablet-wide.jpg" }
    ) {
      ...image
    }
    blogPosts: allWordpressPost(sort: { order: DESC, fields: [date] }) {
      edges {
        node {
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxHeight: 1080) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  presentationWidth
                }
              }
            }
          }
          date(formatString: "DD.MM.YYYY")
          title
          slug
        }
      }
    }
  }
`;
